<template>
    <div>
        <page-title :heading="$t('settings.lang_cardTemplate')" :subheading="$t('settings.lang_editCardTemplate')"
            :icon=icon show-previous-button></page-title>
        <div class="app-main__inner">
            <VoucherbeeCardTemplateEditComponent/>
        </div>
    </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import VoucherbeeCardTemplateEditComponent from "@/components/settings/moduleSettings/onlinegiftcardsale/cardtemplate/VoucherbeeCardTemplateEdit.vue";
export default {
    name: "VoucherbeeCardTemplateEdit",
    components: {
        PageTitle,
        VoucherbeeCardTemplateEditComponent
    },
    data() {
        return {
            icon: 'pe-7s-monitor icon-gradient bg-tempting-azure',
        }
    },
}
</script>

<style scoped></style>