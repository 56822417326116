<template>
    <v-card>
        <v-card-text>
            <v-container fluid>
                <v-form ref="cardTemplateForm" v-model="valid" lazy-validation>
                    <v-row dense>
                        <v-col cols="12" md="6">
                            <v-text-field outlined @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                dense v-model="title" :label="$t('generic.lang_title')" autocomplete="off"
                                :rules="[rules.required]" counter maxlength="50" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field type="number" outlined @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NUMERIC" dense v-model.number="price"
                                :label="$t('generic.lang_price')" autocomplete="off" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-textarea type="number" outlined @focus="showTouchKeyboard" counter maxlength="150"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL" dense v-model="description"
                                :label="$t('generic.lang_description')" autocomplete="off" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-checkbox :label="$t('generic.lang_customizable')" v-model="customizable" hide-details dense />
                        </v-col>
                        <v-col cols="12">
                            <label for="image"><strong>{{ $t("generic.lang_image") }}:</strong></label>
                            <v-slide-group name="image" v-model="imageId" center-active show-arrows dense mandatory>
                                <v-slide-item v-for="(img, n) in filteredImages" :key="n" v-slot="{ active, toggle }"
                                    :value="img.id">
                                    <v-card class="ma-4" height="80" width="150" @click="toggle">
                                        <v-img :src="img.src" width="150" height="80">
                                            <v-scale-transition>
                                                <div class="fill-height w-100 d-flex align-center justify-center"
                                                    :class="active ? 'success' : 'grey lighten-1'"
                                                    :style="{ opacity: active ? 0.7 : 0 }" v-if="active">
                                                    <v-icon color="white" size="48"
                                                        v-text="'mdi-check-circle-outline'"></v-icon>
                                                </div>
                                            </v-scale-transition>
                                        </v-img>
                                    </v-card>
                                </v-slide-item>
                            </v-slide-group>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn color="success" :loading="loading" :disabled="!valid" @click="updateCardTemplate">
                {{ $t('generic.lang_update') }}
            </v-btn>
        </v-card-actions>
        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
                :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible" :layout="touchKeyboard.layout"
                :cancel="hideTouchKeyboard" :accept="hideTouchKeyboard" :input="touchKeyboard.input" />
        </div>
    </v-card>
</template>

<script>
import mixin from '@/mixins/KeyboardMixIns';
import validation from '@/mixins/validation/validation';
import { mapGetters } from 'vuex';
import { ENDPOINTS } from '../../../../../config';
import { Events } from '../../../../../plugins/events';

export default {
    name: "VoucherbeeCardTemplateEdit",
    mixins: [mixin, validation],
    components: {
    },
    computed: {
        ...mapGetters({
            imageUrl: "api/auth/imageUrl",
        }),
        filteredImages() {
            let images = [
                { id: 1, src: require("@/assets/images/voucherbee/vorlage-1.png") },
                { id: 2, src: require("@/assets/images/voucherbee/vorlage-2.png") },
                { id: 3, src: require("@/assets/images/voucherbee/vorlage-3.png") },
                { id: 4, src: require("@/assets/images/voucherbee/vorlage-4.png") },
            ]

            if (this.images?.length > 0) {
                images.unshift(...this.images.map(img => ({ id: img.uuid, src: this.imageUrl(img.image, "150x80") })))
            }

            return images;
        }
    },
    data() {
        return {
            valid: true,
            loading: false,
            images: [],
            title: null,
            price: null,
            description: null,
            imageId: null,
            customizable: false,
        }
    },
    methods: {
        updateCardTemplate() {
            if(!this.$refs.cardTemplateForm.validate()) return;

            this.loading = true;
            this.axios.post(ENDPOINTS.VOUCHERBEE.SETTINGS.CARDTEMPLATE.UPDATE, {
                id: this.$route.params.id,
                title: this.title,
                price: this.price || null,
                description: this.description,
                imageId: this.imageId,
                customizable: this.customizable
            }).then((res) => {
                if (res.data.status === 'SUCCESS') {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_actionSuccessful'),
                        color: "success"
                    });
                    this.$router.go(-1);
                } else {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                }
            }).catch(() => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });
            }).finally(() => {
                this.loading = false;
            })
        },
        getCardTemplate() {
            this.loading = true;

            this.axios.post(ENDPOINTS.VOUCHERBEE.SETTINGS.CARDTEMPLATE.GET, {
                id: this.$route.params.id,
            }).then((res) => {
                if (res.data.status === 'true') {
                    this.title = res.data.data.title
                    this.price = res.data.data.price
                    this.description = res.data.data.description
                    this.imageId = res.data.data.imageId
                    this.customizable = res.data.data.customizable
                }else {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                }
            }).catch(() => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });
            }).finally(() => {
                this.loading = false;
            })
        },
        getImageTemplates() {
            this.loading = true;

            this.axios.post(ENDPOINTS.VOUCHERBEE.SETTINGS.IMAGETEMPLATE.GETALL, {

            }).then((res) => {
                if (res.data.success) {
                    this.images = Object.values(res.data.data) || [];
                }
            }).catch(() => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });
            }).finally(() => {
                this.loading = false;
            })
        },
    },
    mounted() {
        this.getImageTemplates();
        this.getCardTemplate();
    }
}
</script>

<style scoped></style>